
import React, { Component } from "react"
import PropTypes from "prop-types";
import TrackingContext from "../context/TrackingContext"
import { fetchHeaderBids } from "./header-bidding"
import { DidomiSDK } from "@didomi/react"
import { DebugModeLog, isMobile, loadScript } from "../utils" 

class GoogleAds extends Component {

  static contextType = TrackingContext;
  
  constructor( props ) {
    super( props );

    this.adUnits = {};
    this.didomiObject = {};

    this.state = {
      setup: false,
      display: false,
      destroyed: true
    };
  }
  
  async componentDidMount() {
    DebugModeLog('Component did mount', this.state );
    if( this.props.active ) {
        // this.setUpAssertiveYield();
        // await this.setup();
    }

    // Fire google tag conversion on page 10 of NEXT template
    if (this.props.type === 'next' && this.props.page === 10) {
      // window.gtag('event', 'conversion', {'send_to': 'AW-793126717/eYJPCPuw-LMBEL3OmPoC'});
    }
    
  }

  async componentDidUpdate(prevProps, prevState) {
    console.log('TC Debug: Active did update', this.state);
    //console.log('TC Debug: Active did update display', this.state.display);
    if( this.props.active && this.state.destroyed && !this.state.setup ) {
      await this.setup();
    }
    if( !this.state.display && this.state.setup ) {
      await this.displaySlotLines();
      this.setState({
        display: true,
        destroyed: false
      });
    }
  }

  async componentWillUnmount() {
    console.log('TC Debug: Component will unmount', this.state);
    
    if( this.state.display && this.state.setup && !this.state.destroyed ) {
      await this.destroy();
      this.setState({
        setup: false,
        display: false,
        destroyed: true
      });
      console.log('TC Debug: set state', this.state);
    }
  }
  
  setUpAssertiveYield() {
    const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } = this.context;
    loadScript(
      '', 
      true,
      false,
      false,
      `window.assertive = {
          debug: false, // append the query string 'assertiveYield=debug' or add this local storage entry 'localStorage.setItem("assertiveYield", "debug")' to enable dynamically
          entityId: "iv4mspvbgi22vHHfb", // your entity id from the dashboard see: https://suite.assertiveyield.com/welcome,
          analytics: {
              sampleRate: 1, // 1 = all sessions are tracked, 0.5 = 50% of the sessions are tracked...
              custom: {
                  // optional dimensions for custom data, they expect null or string
                  layout: null,
                  userState: null,
                  custom_1: null,
                  custom_2: null,
                  custom_3: null,
                  custom_4: null,
                  custom_5: "${utm_source}",
              },
          },
            hash: {
            generator: 'server', // 'server' or 'client'
            key: function() {
              return ["utm_source=" + "${utm_source}"].join("|");
            },
            values: 714,
          },
          storage: {
          general: 'cookie', // or 'cookie'
          utm: 'cookie', // or 'cookie'
          //bids: 'localStorage', // or 'cookie'
          session: {
              timeout: 120, // minutes of user inactivity after which a session is closed (default 30)
              },
          },
        }`        
  );

  loadScript('/tc-assertiveyield.js', true, false, false);

  loadScript(
    '',
    true,
    false,
    false,
    `window.addEventListener("assertive_logImpression", function (e) {
        var p = e.data.payload;
        p.utm = {
            utm_source: "${utm_source}",
            utm_medium: "${utm_medium}",
            utm_campaign: "${utm_campaign}",
            utm_term: "${utm_term}",
            utm_content: "${utm_content}",
        };
    });
    `
  );

  }

  async setup() {
    DebugModeLog('GPT Setup');

    if( !this.state.setup ) {
      this.getAdUnits();
      DebugModeLog('Setup adunits', this.adUnits );
      window.googletag.cmd.push(() => {
        this.defineSlotLines();
        window.googletag.pubads().disableInitialLoad();
        window.googletag.pubads().enableSingleRequest();
        this.keyValues();
        window.googletag.enableServices();
        //this.displaySlotLines();
      });

      this.setState({
        setup: true
      });
    }

  }

  async destroy() {
    this.adUnits = {};
    window.googletag.cmd.push(() => { window.googletag.destroySlots() });
    window.pbjs.que.push(() => { window.pbjs.removeAdUnit() });
    //console.log('TC Debug: Destroyed adunits', this.adUnits );
  }

  getAdUnits() {
    let adUnits = [];
    if( this.props.type === "standard" ) {
      adUnits = require("./slots/GoogleSlotsStandard.js")
    } else if( this.props.type === "next") {
      adUnits = require("./slots/GoogleSlotsNext.js")
    } else if( this.props.type === "inf") {
      adUnits = require("./slots/GoogleSlotsInfinite.js")
    } else if( this.props.type === "home") {
      adUnits = require("./slots/GoogleSlotsHome.js")
    }
    this.adUnits = adUnits.slots();
  }

  displaySlotLines() {
    this.adUnits.forEach((slot) => {
      if( typeof slot.condition === 'undefined' ) {
        window.googletag.display(slot.id);
      } else {
        if( slot.condition ) {
          window.googletag.display(slot.id);
        }
      }
    });
  }

  defineSlotLines() {
    this.adUnits.forEach((slot) => {
      if( typeof slot.condition === 'undefined' ) {
        window.googletag.defineSlot( slot.path, slot.sizes, slot.id ).addService(window.googletag.pubads())
      }  else {
        if( slot.condition ) {
          window.googletag.defineSlot( slot.path, slot.sizes, slot.id ).addService(window.googletag.pubads())
        }
      }
    });
  }

  keyValues() {
    const tracking = this.context;
    const googleDomain = process.env.GATSBY_SITE_URL_NO_PROTOCOL;
    let device = '';
    const lang = this.props.lang;
    
    DebugModeLog('Tracking', tracking );
    
    for ( const key in tracking ) {
      if( tracking[key] ) {
        window.googletag.pubads().setTargeting( key, tracking[key]);
      }
    }
       if (window.screen.width <= 768 ) {
        device = 'm';
      } else if( window.screen.width > 768 && window.screen.width <= 1024) {
        device = 't';
      } else {
        device = 'd';
      }
       window.googletag.pubads().setTargeting('domain', googleDomain);
       window.googletag.pubads().setTargeting('pubstack', process.env.GATSBY_SITE_URL_NO_PROTOCOL);
       window.googletag.pubads().setTargeting('currentPageTC', this.props.page.toString() );
       window.googletag.pubads().setTargeting('template_global', `${this.props.type}_${device}`);
       window.googletag.pubads().setTargeting('template', `${this.props.type}_${process.env.GATSBY_KEY_VALUE_SHORT_DOMAIN}_${lang}_${device}`);
       let traffic_src = 'no_traffic_source'; 
       if( tracking.utm_source ) { 
         traffic_src = tracking.utm_source.replace(/[-,_].*/,''); 
       }
       window.googletag.pubads().setTargeting('traffic_src', `${traffic_src}_${lang}`);
       window.googletag.pubads().setTargeting('prkv', '0');
       window.assertive.analytics.custom.custom_1 = `${this.props.type}_${process.env.GATSBY_KEY_VALUE_SHORT_DOMAIN}_${lang}_${device}`;
       window.assertive.analytics.custom.custom_2 = tracking.abtest;
       window.assertive.analytics.custom.custom_3 = `${this.props.type}_${device}`;
       window.assertive.analytics.custom.custom_4 = traffic_src;
       
  }

  pbjsAdUnits() {
    let adunits = [];
    
    this.adUnits.forEach((slot, index) => {
  
      let bidders = isMobile() ? slot.mobile_bidders : slot.bidders;
     
      // Set up tags for pubstack
      const tracking = this.context;
      const abtestValue = localStorage.getItem( 'abtest' );
      const lang = this.props.lang;
      const device = isMobile() ? 'm' : 'd';
      const templateValue = `${this.props.type}_${process.env.GATSBY_KEY_VALUE_SHORT_DOMAIN}_${lang}_${device}`;
      let traffic_src = 'no traffic source'; 
      if ( tracking.utm_source ) { 
        traffic_src = tracking.utm_source.replace(/[-,_].*/,''); 
      }
      //const pubstackTags = [ 'abtest:' + abtestValue, 'template:' + templateValue, 'traffic_src:' + traffic_src + '_' + lang];
      const pubstackTags = { adUnitName: slot.name, adUnitPath: slot.path + '?pubstack=' + process.env.GATSBY_SITE_URL_NO_PROTOCOL, tags: [ 'abtest:' + abtestValue, 'template:' + templateValue, 'traffic_src:' + traffic_src + '_' + lang] }


      if(slot.bidders && typeof slot.condition === 'undefined') {
        adunits.push({
          code: slot.id,
          pubstack: pubstackTags,
          mediaTypes: {
              banner: {
                sizes: slot.sizes
              }
          },
          bids: bidders
        });
      } else {
        if( slot.condition ) {
          adunits.push(
            {
              code: slot.id,
              pubstack: pubstackTags,
              mediaTypes: {
                  banner: {
                    sizes: slot.sizes
                  }
              },
              bids: bidders
            }
          );
        }
      }
    });
    return adunits;
  }

  apsAdUnits() {
    let adunits = [];
    this.adUnits.forEach((slot, index) => {
      adunits.push(
        {
          slotID: slot.id,
          sizes: slot.sizes,
          slotName: slot.path
        }
      );
    });
    return adunits;
  }

  onDidomiReady( Didomi ) {
    this.didomiObject = Didomi;
  }

  acceptConsentOnScroll = () => {    
    var windowHeight = window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight || 0;
    var docHeight = Math.max(
        document.body.scrollHeight || 0,
        document.documentElement.scrollHeight || 0,
        document.body.offsetHeight || 0,
        document.documentElement.offsetHeight || 0,
        document.body.clientHeight || 0,
        document.documentElement.clientHeight || 0
    );
    var yScroll = window.pageYOffset ||
        document.body.scrollTop ||
        document.documentElement.scrollTop || 0;

    var scrollPercent = (yScroll / (docHeight - windowHeight)) * 100;

    if (scrollPercent >= 0.01 && typeof this.didomiObject != 'undefined') {
      this.didomiObject.setUserAgreeToAll();
    }
  }
  

  render() {
    //const { active } = this.state;
    //console.log('activate', active);
    
    return(
        <DidomiSDK
          apiKey="8850ee0e-763c-4208-95ce-002d4325612e"
          iabVersion={2} // If you want to support the TCF v1∏, don't forget to change this value, even if you selected the TCF v2 in the console. This parameter will load the correct stub in the React Component
          noticeId="djrpE8jW" // If you want to target the notice by ID and not by domain
          gdprAppliesGlobally={false}
          sdkPath="https://sdk.privacy-center.org/"
          embedTCFStub={true}
          onReady={ Didomi => {
            this.onDidomiReady( Didomi );
              //console.log('Didomi SDK is loaded and ready', Didomi)
              // window.apsAdUnits = this.apsAdUnits();
              // window.pbjsAdUnits = this.pbjsAdUnits();

              if( Didomi.isConsentRequired() ) {
                //console.log('Consent required');
                const userStatus = Didomi.getUserStatus();
                if ( userStatus.purposes.consent.enabled.length > 0 || userStatus.purposes.consent.disabled.length > 0 ) {
                    //console.log('User already has consent');

                    fetchHeaderBids(window.apsAdUnits, window.pbjsAdUnits, 3000);
                    window.removeEventListener('scroll', this.acceptConsentOnScroll );
                }
              } else {
                //console.log('Consent not required');
                fetchHeaderBids(window.apsAdUnits, window.pbjsAdUnits, 3000);
                window.removeEventListener('scroll', this.acceptConsentOnScroll );
              }
            }
          }
          onConsentChanged={cwtToken => {
              //console.log('A consent has been given/withdrawn', cwtToken);
              fetchHeaderBids(window.apsAdUnits, window.pbjsAdUnits, 3000);
              window.removeEventListener('scroll', this.acceptConsentOnScroll );
            }
          }
          onNoticeShown={() => {
              window.addEventListener('scroll', this.acceptConsentOnScroll )
            }
          }
        />
    );
  }
}

export default GoogleAds

export const LoadInfSlot = (index, key, type, lang, utm_source, prebidTimeout ) => {
  
  // Set up tags for pubstack
  const abtestValue = localStorage.getItem( 'abtest' );
  const device = isMobile() ? 'm' : 'd';
  const templateValue = `${type}_${process.env.GATSBY_KEY_VALUE_SHORT_DOMAIN}_${lang}_${device}`;
  let traffic_src = 'no traffic source'; 
  if ( utm_source ) { 
    traffic_src = utm_source.replace(/[-,_].*/,''); 
  }

  let PREBID_TIMEOUT = prebidTimeout;
  let bidTimeout = 3000;

  var infsAmazon = {}, amazonBidCheck = function (key, from) {
    infsAmazon[key][from] = true;
    var item = infsAmazon[key];

    console.log(">>>> amazon + prebid:", item);
    if (item.amzn && item.pbjs) {
        window.googletag.pubads().refresh([infiniteScrollSlots[key]]);
        console.log(">>>> amazon + prebid: refreshing");
    }
  };

  var infiniteScrollSlots = {};

  infsAmazon[key] = {
    pbjs: false,
    amzn: false,
  };

  let adUnit;
  if (window.innerWidth <= 812) {
    adUnit = require("./slots/GoogleSlotsInfinite.js").slots().filter( slot => slot.id === 'MPU_Parallax')[0];
  } else {
    adUnit = require("./slots/GoogleSlotsInfinite.js").slots().filter( slot => slot.id === 'LeaderDesktopInfinite')[0];
  }

  const pubstackTags = { adUnitName: adUnit.name, adUnitPath: adUnit.path + '?pubstack=' + process.env.GATSBY_SITE_URL_NO_PROTOCOL, tags: [ 'abtest:' + abtestValue, 'template:' + templateValue, 'traffic_src:' + traffic_src + '_' + lang] }

  window.googletag.cmd.push(function () {

      let size = adUnit.sizes; 
      let bids = adUnit.bidders;

      infiniteScrollSlots[key] = window.googletag
      .defineSlot(adUnit.path, size, key)
      .addService(window.googletag.pubads());

      window.googletag.display(key);
    
    var fetchPrebid = (function (_key, _bids, _size) {
       
        return function (callback) {
            if (_bids.length !== 0) {
                window.pbjs.que.push(function() {
                    window.pbjs.addAdUnits({
                        code: _key,
                        mediaTypes: { banner: { sizes: _size } },
                        pubstack: pubstackTags,
                        bids: _bids,
                    });

                    window.pbjs.requestBids({
                        adUnitCodes: [_key],
                        timeout: PREBID_TIMEOUT,
                        bidsBackHandler: function () {
                            window.pbjs.setTargetingForGPTAsync([_key]);
                                        callback()
                        },
                    });
                    
                });
            } else {
                window.googletag.pubads().refresh([infiniteScrollSlots[_key]]);
            }
        };
   })(key, bids, size);

   var amazonSlots = [{
       slotID: key,
       sizes: size,
       slotName: adUnit.path
   }];

    try {
       fetchPrebid(function () {
        amazonBidCheck(key, "pbjs");
       });
       window.apstag.fetchBids({
           slots: amazonSlots,
           timeout: bidTimeout
       }, function (amznBids) {
           window.apstag.setDisplayBids();
           amazonBidCheck(key, "amzn");
       });
   } catch (e) {
       fetchPrebid(function () {
        amazonBidCheck(key, "amzn");
        amazonBidCheck(key, "pbjs");
       });
   }
  });
};

GoogleAds.propTypes = {
  active: PropTypes.bool,
  type: PropTypes.string,
};

GoogleAds.defaultProps = {
  active: true,
};