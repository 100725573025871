import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {

  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  let adUnits = [
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderBelowTitle",
        "name": "NEXT_Leader_BellowTitle",
        "path": "/76730613/NEXT_Leader_BellowTitle",
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
        "bidders": [
          // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'LeaderBelowTitle', environment:'desktop', placement:'NEXT_Leader_BellowTitle' } },
          // { bidder: 'adform', params: { mid: 1182007 } },
          // { bidder: 'appnexus', params: { placementId: '23075382' } },
          // { bidder: 'criteo', params: { networkId: 8147 } },
          // { bidder: 'ix', params: {siteId: 752382, size: [728,90] } },
          // //{ bidder: 'justpremium', params: {zone: 133775} },
          // { bidder: 'openx', params: { unit: '556399831', delDomain: 'lqr-d.openx.net'} },
          // { bidder: 'onemobile',params: {dcn: '8a969449017c7c9d384d9e6b99160043',pos: '8a9690e9017c7ca4073fa7a1d9da0069',ext: {}} },
          // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_Leader_bellowTitle' } },
          // { bidder: 'sovrn', params:{ tagid: 972312 } },
          // //{ bidder: 'teads', params: { pageId: '139117', placementId: '138868'} },
          // { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_NEXT_Leader_bellowTitle_Prebid' } },
          // { bidder: 'yieldone', params: {placementId: '133369'} },
          // { bidder: 'sharethrough', params: {pkey: 'd1VS2AhMMVEaoHZXWx9yZ4LL'} },
          // { bidder: 'sharethrough', params: {pkey: 'Ad2dlzALC7ePFb9JX4T5Ib4R'} }
        ],
        "mobile_bidders": [
          // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:'NEXT_Leader_BellowTitle' } },
          // { bidder:  'adform', params: { mid: 1182013 } },
          // { bidder: 'appnexus', params: { placementId: '23075456' } },
          // { bidder: 'criteo', params: { networkId: 8147 } },
          // { bidder: 'ix', params: {siteId: 752395, size: [300,250] } },
          // { bidder: 'ix', params: {siteId: 752395, size: [336,280] } },
          // { bidder: 'ix', params: {siteId: 752395, size: [320,100] } },
          // //{ bidder: 'justpremium', params: {zone: 133775} },
          // { bidder: 'onemobile',params: {dcn: '8a969958017c7c9d2d8a9e6a6fca0050',pos: '8a9690e9017c7ca4073fa7a1eab1006b',ext: {}} },
          // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          // { bidder: 'openx', params: { unit: '556399836', delDomain: 'lqr-d.openx.net'} },
          // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_Leader_bellowTitle' } },
          // { bidder: 'sovrn', params:{ tagid: 972331 } },
          // { bidder: 'sovrn', params:{ tagid: 972332 } },
          // { bidder: 'sovrn', params:{ tagid: 972333 } },
          // //{ bidder: 'teads', params: {pageId: '139117', placementId: '138868'} },
          // { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_NEXT_Leader_belowTitle_Prebid'} },
          // { bidder: 'yieldone', params: {placementId: '133376'} },
          // { bidder: 'sharethrough', params: {pkey: 'SoAz30yVY7xYis0Jmz0Jbyhl'} },
          // { bidder: 'sharethrough', params: {pkey: 'd1VS2AhMMVEaoHZXWx9yZ4LL'} },
          // { bidder: 'sharethrough', params: {pkey: 'Ad2dlzALC7ePFb9JX4T5Ib4R'} }
        ]
      },
      {
        "condition": window.screen.width < 812,
        "id": "MPUTopRight",
        "name": "NEXT_MPUTopRight",
        "path": "/76730613/NEXT_MPUTopRight",
        "sizes": [[300, 250],[336,280],[320,100]],
        "mobile_bidders": [
          // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'MPUTopRight', environment:'mobile', placement:'NEXT_MPUTopRight' } },
          // { bidder: 'adform', params: { mid: 1182015 } },
          // { bidder: 'appnexus', params: { placementId: '23075460' } },
          // { bidder: 'criteo', params: { networkId: 8147 } },
          // { bidder: 'ix',  params: { siteId: 752397, size: [300, 250] } },
          // { bidder: 'ix',  params: { siteId: 752397, size: [336, 280] } },
          // { bidder: 'ix',  params: { siteId: 752397, size: [320, 100] } },
          // //{ bidder: 'justpremium', params: {zone: 133775} },
          // { bidder: 'onemobile', params: { dcn:'8a969958017c7c9d2d8a9e6a6fca0050', pos:'8a969887017c7ca40217a7a1ee4f0072',ext: {}} },
          // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          // { bidder: 'openx', params: { unit: '556399838', delDomain: 'lqr-d.openx.net' } },
          // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_MPUTopRight' } },
          // { bidder: 'sovrn', params:{ tagid: 972336 } },
          // { bidder: 'sovrn', params:{ tagid: 972337 } },
          // { bidder: 'sovrn', params:{ tagid: 972338 } },
          // { bidder: 'teads', params: { pageId: '139117', placementId: '152655'} },
          // { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_NEXT_MPUTopRight_Prebid'} },
          // { bidder: 'yieldone', params: {placementId: '133378'} },
          // { bidder: 'sharethrough', params: {pkey: '2GgwCSI8CkfNJdFRdxYWntAN'} },
          // { bidder: 'sharethrough', params: {pkey: 'WxDusIQ31ZfhhDWyePVFGVHf'} },
          // { bidder: 'sharethrough', params: {pkey: 'XXk5LSINw2Bk3F5E9GeirtS9'} }
        ]
      },
      {
        "condition": window.screen.width > 812,
        "id": "RightColumn",
        "name": "NEXT_RightColumnHalfPage1",
        "path": "/76730613/NEXT_RightColumnHalfPage1",
        "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600]] : [[160, 600]],
        "bidders": [
          // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'RightColumn', environment:'desktop', placement:'NEXT_RightColumnHalfPage1' } },
          // { bidder: 'adform', params: { mid: 1182038 } },
          // { bidder: 'appnexus', params: {placementId: '23075408'} },
          // { bidder: 'criteo', params: { networkId: 8147 } },
          // { bidder: 'ix', params: {siteId: 752386, size:[160, 600]} },
          // { bidder: 'ix', params: {siteId: 752386, size:[300, 600]} },
          // //{ bidder: 'justpremium', params: {zone: 133775} },
          // { bidder: 'onemobile',params: {dcn: '8a969449017c7c9d384d9e6b99160043',pos: '8a969887017c7ca40217a7a1e35f0070',ext: {}} },
          // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          // { bidder: 'openx', params: {unit: '556399835', delDomain: 'lqr-d.openx.net'} },
          // // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
          // { bidder: 'sovrn', params:{ tagid: 972304 } },
          // { bidder: 'sovrn', params:{ tagid: 972305 } },
          // { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
          // { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_NEXT_RightColumnHalfPage1_Prebid' } },
          // { bidder: 'yieldone', params: {placementId: '133365'} },
          // { bidder: 'sharethrough', params: {pkey: '3RC9iI5xFzB8M2qvdgawB80U'} }
        ]
      },
      {
        "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeftColumn",
        "name": "NEXT_LeftColumnHalfPage",
        "path": "/76730613/NEXT_LeftColumnHalfPage",
        "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600]] : [[160, 600]],
        "bidders": [
          // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'LeftColumn', environment:'desktop', placement:'NEXT_LeftColumnHalfPage' } },
          // { bidder: 'adform', params: { mid: 1182045 } },
          // { bidder: 'appnexus', params: {placementId: '23075383'} },
          // { bidder: 'criteo', params: { networkId: 8147 } },
          // { bidder: 'ix', params: {siteId: 752383, size:[160, 600]} },
          // { bidder: 'ix', params: {siteId: 752383, size:[300, 600]}},
          // { bidder: 'justpremium', params: {zone: 133775, allow: ["sa"]} },
          // { bidder: 'onemobile',params: {dcn: '8a969449017c7c9d384d9e6b99160043',pos: '8a9690e9017c7ca4073fa7a1e525006a',ext: {}} },
          // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          // { bidder: 'openx', params: {unit: '556399844', delDomain: 'lqr-d.openx.net'} },
          // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
          // { bidder: 'sovrn', params:{ tagid: 972307 } },
          // { bidder: 'sovrn', params:{ tagid: 972306 } },
          // { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
          // { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_NEXT_LeftColumn_Prebid' } },
          // { bidder: 'yieldone', params: {placementId: '133366'} },
          // { bidder: 'sharethrough', params: {pkey: 'GwDQHaMHzEhZRHB5yBHBvaDC'} }
        ]
      },
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "StickyBanner",
        "name": window.screen.width < 812 ? 'NEXT_StickyBannerMobile' : 'NEXT_StickyBannerDesktop',
        "path": window.screen.width < 812 ? '/76730613/NEXT_StickyBannerMobile' : '/76730613/NEXT_StickyBannerDesktop',
        "sizes": window.screen.width < 812 ? [[320, 50],[300,50],[360,50],[320,100],[300,100],[360,100]] : [[728, 90]],
        "bidders": [
          // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'StickyBanner', environment:'desktop', placement:'NEXT_StickyBannerDesktop' } },
          // { bidder: 'adform', params: { mid: 1182044 } },
          // { bidder: 'appnexus', params: {placementId: '23075409'} },
          // { bidder: 'criteo', params: { networkId: 8147 } },
          // { bidder: 'ix', params: {siteId: 752387, size:[728, 90]} },
          // //{ bidder: 'justpremium', params: {zone: 133775} },
          // { bidder: 'onemobile',params: {dcn: '8a969449017c7c9d384d9e6b99160043',pos: '8a9695b0017c7ca40d44a7a1e704005d',ext: {}} },
          // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          // { bidder: 'openx', params: {unit: '556399847', delDomain: 'lqr-d.openx.net'} },
          // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
          // { bidder: 'sovrn', params:{ tagid: 972313 } },
          // //{ bidder: 'teads', params: {pageId: '139117', placementId: '138867'} },
          // { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_NEXT_StickyBanner_Prebid' } },
          // { bidder: 'yieldone', params: {placementId: '133370'} },
          // { bidder: 'sharethrough', params: {pkey: 'Milfjjy5NHZ4I8o83tnXTA6I'} }
        ],
        "mobile_bidders": [
          // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'StickyBanner', environment:'mobile', placement:'NEXT_Stickybanner' } },
          // { bidder: 'adform', params: { mid: 1182016 } },
          // { bidder: 'appnexus', params: { placementId: '23199209' } },
          // { bidder: 'criteo', params: { networkId: 8147 } },
          // { bidder: 'ix', params: {siteId: 752399, size: [320,50]} },
          // { bidder: 'ix', params: {siteId: 752399, size: [300,50]} },
          // { bidder: 'ix', params: {siteId: 752399, size: [360,50]} },
          // { bidder: 'ix', params: {siteId: 752399, size: [320,100]} },
          // { bidder: 'ix', params: {siteId: 752399, size: [300,100]} },
          // { bidder: 'ix', params: {siteId: 752399, size: [360,100]} },
          // { bidder: 'justpremium', params: {zone: 133775, allow: ["ms"]} },
          // { bidder: 'onemobile',params: {dcn: '8a969958017c7c9d2d8a9e6a6fca0050',pos: '8a9690e9017c7ca4073fa7a1f013006c',ext: {}} },
          // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          // { bidder: 'openx', params: { unit: '556399825', delDomain: 'lqr-d.openx.net' } },
          // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_StickyBannerMobile' } },
          // { bidder: 'sovrn', params:{ tagid: 972342 } },
          // { bidder: 'sovrn', params:{ tagid: 972343 } },
          // { bidder: 'sovrn', params:{ tagid: 972344 } },
          // //{ bidder: 'teads', params: {pageId: '139117', placementId: '138867'} },
          // { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_NEXT_StickyBanner_Prebid'} },
          // { bidder: 'yieldone', params: {placementId: '133380'} },
          // { bidder: 'sharethrough', params: {pkey: 'N6taX0lBkGf4vOam9gkTFLFW'} },
          // { bidder: 'sharethrough', params: {pkey: 'aAC0k4LZAPZ5tNgffqyCnXHr'} }
        ]
      },
      {
        "id": "MPUBelowNext",
        "name": "NEXT_MPUBelowNext",
        "path": "/76730613/NEXT_MPUBelowNext",
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[300, 250],[336,280]],
        "bidders": [
          // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'MPUBelowNext', environment:'desktop', placement:'NEXT_MPUBelowNext' } },
          // { bidder: 'adform', params: { mid: 1182008 } },
          // { bidder: 'appnexus', params: {placementId: '23075406'} },
          // { bidder: 'criteo', params: { networkId: 8147 } },
          // { bidder: 'ix', params: {siteId: 752384, size:[300, 250]} },
          // { bidder: 'ix', params: {siteId: 752384, size:[336, 280]} },
          // //{ bidder: 'justpremium', params: {zone: 133775} },
          // { bidder: 'onemobile',params: {dcn: '8a969449017c7c9d384d9e6b99160043',pos: '8a969887017c7ca40217a7a1e8eb0071',ext: {}} },
          // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          // { bidder: 'openx', params: {unit: '556399832', delDomain: 'lqr-d.openx.net'} },
          // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
          // { bidder: 'sovrn', params:{ tagid: 972310 } },
          // { bidder: 'sovrn', params:{ tagid: 972311 } },
          // { bidder: 'teads', params: {pageId: '139117', placementId: '152656'} },
          // { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_NEXT_MPUBelowNext_Prebid' } },
          // { bidder: 'yieldone', params: {placementId: '133368'} },
          // { bidder: 'sharethrough', params: {pkey: 'knVAqddR43jMYYmHjz7AOBNQ'} },
          // { bidder: 'sharethrough', params: {pkey: 'yp3RU1mysSjgOhSIcrXOoXGj'} }
        ],
        "mobile_bidders": [
          // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'MPUBelowNext', environment:'mobile', placement:'NEXT_MPUBelowNext' } },
          // { bidder: 'adform', params: { mid: 1182012 } },
          // { bidder: 'appnexus', params: {placementId: '23075406'} },
          // { bidder: 'criteo', params: { networkId: 8147 } },
          // { bidder: 'ix', params: {siteId: 752394, size:[300, 250]} },
          // { bidder: 'ix', params: {siteId: 752394, size:[336, 280]} },
          // { bidder: 'ix', params: {siteId: 752394, size:[320, 100]} },
          // //{ bidder: 'justpremium', params: {zone: 133775} },
          // { bidder: 'onemobile',params: {dcn: '8a969958017c7c9d2d8a9e6a6fca0050',pos: '8a9695b0017c7ca40d44a7a1f1ee005f',ext: {}} },
          // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          // { bidder: 'openx', params: {unit: '556399826', delDomain: 'lqr-d.openx.net'} },
          // // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
          // { bidder: 'sovrn', params:{ tagid: 972334 } },
          // { bidder: 'sovrn', params:{ tagid: 972335 } },
          // { bidder: 'teads', params: {pageId: '139117', placementId: '152656'} },
          // { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_mobile_NEXT_MPUBelowNext_Prebid' } },
          // { bidder: 'yieldone', params: {placementId: '133377'} },
          // { bidder: 'sharethrough', params: {pkey: 'F8kJOV5y7BqW53XOyxDDVEl8'} },
          // { bidder: 'sharethrough', params: {pkey: 'knVAqddR43jMYYmHjz7AOBNQ'} },
          // { bidder: 'sharethrough', params: {pkey: 'yp3RU1mysSjgOhSIcrXOoXGj'} }
        ]
      },
      {
        "id": "MPUCenterRight",
        "name": "NEXT_MPUCenterRight",
        "path": "/76730613/NEXT_MPUCenterRight",
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[300, 250],[336,280]],
        "bidders": [
          // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'MPUCenterRight', environment:'desktop', placement:'NEXT_MPUCenterRight' } },
          // { bidder:  'adform', params: { mid: 1182009 } },
          // { bidder: 'appnexus', params: { placementId: '23075457' } },
          // { bidder: 'criteo', params: { networkId: 8147 } },
          // { bidder: 'ix',  params: { siteId: 752385, size: [300, 250] } },
          // { bidder: 'ix',  params: { siteId: 752385, size: [336, 280] } },
          // //{ bidder: 'justpremium', params: {zone: 133775} },
          // { bidder: 'onemobile',params: {dcn: '8a969449017c7c9d384d9e6b99160043',pos: '8a9695b0017c7ca40d44a7a1dbc2005c',ext: {}} },
          // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          // { bidder: 'openx', params: { unit: '556399833', delDomain: 'lqr-d.openx.net' } },
          // ////{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUCenterRight' } },
          // { bidder: 'sovrn', params:{ tagid: 972308 } },
          // { bidder: 'sovrn', params:{ tagid: 972309 } },
          // { bidder: 'teads', params: {pageId: '139117', placementId: '152656'} },
          // { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_NEXT_MPUCenterRight_Prebid'} },
          // { bidder: 'yieldone', params: {placementId: '133367'} },
          // { bidder: 'sharethrough', params: {pkey: 'cK14dxc7F6VwKXCsw3cjYPo2'} },
          // { bidder: 'sharethrough', params: {pkey: 'mEt9b2XPj21NeyNPkModwGgg'} }
        ],
        "mobile_bidders": [
          // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'MPUCenterRight', environment:'mobile', placement:'NEXT_MPUCenterRight' } },
          // { bidder: 'adform', params: { mid: 1182014 } },
          // { bidder: 'appnexus', params: { placementId: '1182009' } },
          // { bidder: 'criteo', params: { networkId: 8147 } },
          // { bidder: 'ix',  params: { siteId: 752396, size: [300, 250] } },
          // { bidder: 'ix',  params: { siteId: 752396, size: [336, 280] } },
          // { bidder: 'ix',  params: { siteId: 752396, size: [320, 100] } },
          // //{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
          // { bidder: 'onemobile' ,params: { dcn:'8a969958017c7c9d2d8a9e6a6fca0050', pos:'8a9695b0017c7ca40d44a7a1ec77005e',ext: {}} },
          // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          // { bidder: 'openx', params: { unit: '556399837', delDomain: 'lqr-d.openx.net' } },
          // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_MPUCenterRight' } },
          // { bidder: 'sovrn', params:{ tagid: 972339 } },
          // { bidder: 'sovrn', params:{ tagid: 972340 } },
          // { bidder: 'sovrn', params:{ tagid: 972341 } },
          // { bidder: 'teads', params: {pageId: '139117', placementId: '152656'} },
          // { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_NEXT_MPUCenterRight_Prebid'} },
          // { bidder: 'yieldone', params: {placementId: '133379'} },
          // { bidder: 'sharethrough', params: {pkey: 'cK14dxc7F6VwKXCsw3cjYPo2'} },
          // { bidder: 'sharethrough', params: {pkey: 'cK14dxc7F6VwKXCsw3cjYPo2'} },
          // { bidder: 'sharethrough', params: {pkey: 'mEt9b2XPj21NeyNPkModwGgg'} }
        ],
      },
    ];


  /**
   * Finds left and right columns, pushes the missing bidders
   * and pushes correct bidders to the adunit
   */
    if (window.screen.width > 1440) {
        let rcMissingIX = adUnits.find(ad => ad.id === 'RightColumn');
        let lcMissingIX = adUnits.find(ad => ad.id === 'LeftColumn');
        rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 752386, size:[300, 600]} });
        lcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 752383, size:[300, 600]} });
      }
      return adUnits;
}