import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {

  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  let adUnits = [
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_') && !checkUtmSourceStartsWith('fb-'),
      "id": "LeaderBelowTitle",
      "name": "Leader_BellowTitle",
      "path": "/76730613/Leader_BellowTitle",
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
      "bidders": [
        // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'LeaderBelowTitle', environment:'desktop', placement:'Leader_BellowTitle' } },
        // { bidder: 'adform', params: { mid: 1182021 } },
        // { bidder: 'appnexus', params: { placementId: '23075358' } },
        // { bidder: 'criteo', params: { networkId: 8147 } },
        // { bidder: 'ix', params: {siteId: 752372, size: [728,90] } },
        // //{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
        // { bidder: 'openx', params: { unit: '556399817', delDomain: 'lqr-d.openx.net'} },
        // { bidder: 'onemobile',params: {dcn: '8a969449017c7c9d384d9e6b99160043',pos: '8a969887017c7ca40217a7a1f3e70073',ext: {}} },
        // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_Leader_bellowTitle' } },
        // { bidder: 'sovrn', params:{ tagid: 972301 } },
        // //{ bidder: 'teads', params: { pageId: '139117', placementId: '138871'} },
        // { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_desktop_Leader_bellowTitle_Prebid'} },
        // { bidder: 'yieldone', params: {placementId: '133363'} },
        // { bidder: 'sharethrough', params: {pkey: 'ahIUaXcPkiEFtTuTqmdRl8Ih'} }
      ],
      "mobile_bidders": [
        // { bidder: 'adform', params: { mid: 1182026 } },
        // { bidder: 'appnexus', params: { placementId: '23075452' } },
        // { bidder: 'criteo', params: { networkId: 8147 } },
        // { bidder: 'ix', params: {siteId: 752390, size: [300,250] } },
        // { bidder: 'ix', params: {siteId: 752390, size: [336,280] } },
        // { bidder: 'ix', params: {siteId: 752390, size: [320,100] } },
        // //{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
        // { bidder: 'onemobile',params: {dcn: '8a969958017c7c9d2d8a9e6a6fca0050',pos: '8a9695b0017c7ca40d44a7a1fda10061',ext: {}} },
        // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        // { bidder: 'openx', params: { unit: '556399822', delDomain: 'lqr-d.openx.net'} },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_Leader_bellowTitle' } },
        // { bidder: 'sovrn', params:{ tagid: 972325 } },
        // { bidder: 'sovrn', params:{ tagid: 972326 } },
        // { bidder: 'sovrn', params:{ tagid: 972327 } },
        // //{ bidder: 'teads', params: {pageId: '139117', placementId: '138871'} },
        // { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_Leader_bellowTitle_Prebid'} },
        // { bidder: 'yieldone', params: {placementId: '127662'} },
        // { bidder: 'sharethrough', params: {pkey: 'L6DpNWi1QmdUHd1vDbTzgf7r'} },
        // { bidder: 'sharethrough', params: {pkey: 'RsjBpXK6GXih3dWWpaTjzTup'} }
      ]
    },
    {
      "condition": window.screen.width < 812,
      "id": "MPUTopRight",
      "name": "MPUTopRight",
      "path": "/76730613/MPUTopRight",
      "sizes": [[300, 250],[336,280],[320,100]],
      "mobile_bidders": [
        // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'MPUTopRight', environment:'mobile', placement:'MPUTopRight' } },
        // { bidder: 'adform', params: { mid: 1182028 } },
        // { bidder: 'appnexus', params: { placementId: '23075455' } },
        // { bidder: 'criteo', params: { networkId: 8147 } },
       
        // { bidder: 'ix',  params: { siteId: 752393, size: [300, 250] } },
        // { bidder: 'ix',  params: { siteId: 752393, size: [336, 280] } },
        // { bidder: 'ix',  params: { siteId: 752393, size: [320, 100] } },
        // //{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
        // { bidder: 'onemobile', params: { dcn:'8a969958017c7c9d2d8a9e6a6fca0050', pos:'8a969887017c7ca40217a7a1ff830075',ext: {}} },
        // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        // { bidder: 'openx', params: { unit: '556399824', delDomain: 'lqr-d.openx.net' } },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_MPUTopRight' } },
        // { bidder: 'sovrn', params:{ tagid: 972322 } },
        // { bidder: 'sovrn', params:{ tagid: 972323 } },
        // { bidder: 'sovrn', params:{ tagid: 972324 } },
        // { bidder: 'teads', params: { pageId: '139117', placementId: '152659'} },
        // { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_MPUTopRight_Prebid'} },
        // { bidder: 'yieldone', params: {placementId: '133373'} },
        // { bidder: 'sharethrough', params: {pkey: 'kjXuuTlOhwUQAPvV105P026L'} },
        // { bidder: 'sharethrough', params: {pkey: 'iYqhkM5eJeBa9zGLtFNyEVjR'} },
        // { bidder: 'sharethrough', params: {pkey: 'i1jMuVeSukEUN6uRf7glHDru'} }
      ]
    },
    {
      "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('fb-'),
      "id": "RightColumn",
      "name": "RightColumn1",
      "path": "/76730613/RightColumn1",
      "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600]] : [[160, 600]],
      "bidders": [
        // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'RightColumn', environment:'desktop', placement:'RightColumn1' } },
        // { bidder: 'adform', params: { mid: 1182039 } },
        // { bidder: 'appnexus', params: {placementId: '23075410'} },
        // { bidder: 'criteo', params: { networkId: 8147 } },
        // { bidder: 'ix', params: {siteId: 752388, size:[160, 600]} },
        // //{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
        // { bidder: 'onemobile',params: {dcn: '8a969449017c7c9d384d9e6b99160043',pos: '8a9690e9017c7ca4073fa7a1f5f8006d',ext: {}} },
        // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        // { bidder: 'openx', params: {unit: '556399821', delDomain: 'lqr-d.openx.net'} },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        // { bidder: 'sovrn', params:{ tagid: 972299 } },
        // { bidder: 'sovrn', params:{ tagid: 972300 } },
        // { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
        // { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_RightColumnHalfPage1_Prebid' } },
        // { bidder: 'yieldone', params: {placementId: '133362'} },
        // { bidder: 'sharethrough', params: {pkey: 'QB3D6Er7I92VWuswhQczkPWv'} }
      ]
      
    },
    {
      "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('yahoo_') && !checkUtmSourceStartsWith('fb-'),
      "id": "LeftColumn",
      "name": "LeftColumnHalfPage",
      "path": "/76730613/LeftColumnHalfPage",
      "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600]] : [[160, 600]],
      "bidders": [
        // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'LeftColumn', environment:'desktop', placement:'LeftColumnHalfPage' } },
        // { bidder: 'adform', params: { mid: 1182046 } },
        // { bidder: 'appnexus', params: {placementId: '23075377'} },
        // { bidder: 'criteo', params: { networkId: 8147 } },
        // { bidder: 'ix', params: {siteId: 752381, size:[160, 600]} },
        // { bidder: 'justpremium', params: {zone: 133775, allow: ["sa"]} },
        // { bidder: 'onemobile',params: {dcn: '8a969449017c7c9d384d9e6b99160043',pos: '8a9695b0017c7ca40d44a7a1f7fd0060',ext: {}} },
        // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        // { bidder: 'openx', params: {unit: '556399845', delDomain: 'lqr-d.openx.net'} },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        // { bidder: 'sovrn', params:{ tagid: 972302 } },
        // { bidder: 'sovrn', params:{ tagid: 972303 } },
        // { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
        // { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_LeftColumn_Prebid' } },
        // { bidder: 'yieldone', params: {placementId: '133364'} },
        // { bidder: 'sharethrough', params: {pkey: 'eQn64ZvBTwXfO4COZ4VPPO6c'} }
      ],
    },

    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "StickyBanner",
      "name": window.screen.width < 812 ? 'StickyBannerMobile' : 'StickyBannerDesktop',
      "path": window.screen.width < 812 ? '/76730613/StickyBannerMobile' : '/76730613/StickyBannerDesktop',
      "sizes": window.screen.width < 812 ? [[320, 50],[300,50],[360,50],[320,100],[300,100],[360,100]] : [[728, 90]],
      "bidders": [
        // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'StickyBanner', environment:'desktop', placement:'StickyBannerDesktop' } },
        // { bidder: 'adform', params: { mid: 1182043 } },
        // { bidder: 'appnexus', params: {placementId: '23075411'} },
        // { bidder: 'criteo', params: { networkId: 8147 } },
        // { bidder: 'ix', params: {siteId: 752389, size:[728, 90]} },
        // //{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
        // { bidder: 'onemobile',params: {dcn: '8a969449017c7c9d384d9e6b99160043',pos: '8a9690e9017c7ca4073fa7a1fbc3006e',ext: {}} },
        // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        // { bidder: 'openx', params: {unit: '556399846', delDomain: 'lqr-d.openx.net'} },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        // { bidder: 'sovrn', params:{ tagid: 972298 } },
        // //{ bidder: 'teads', params: {pageId: '139117', placementId: '138867'} },
        // { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_StickyBanner_Prebid' } },
        // { bidder: 'yieldone', params: {placementId: '133361'} },
        // { bidder: 'sharethrough', params: {pkey: 'wD3NJmTeDc8Sb7PwboxNfyp9'} }
      ],
      "mobile_bidders": [
        // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'StickyBanner', environment:'mobile', placement:'StickyBannerMobile' } },
        // { bidder: 'adform', params: { mid: 1182029 } },
        // { bidder: 'appnexus', params: { placementId: '23075461' } },
        // { bidder: 'criteo', params: { networkId: 8147 } },
        // { bidder: 'ix', params: {siteId: 752400, size: [320,50]} },
        // { bidder: 'ix', params: {siteId: 752400, size: [300,50]} },
        // { bidder: 'ix', params: {siteId: 752400, size: [360,50]} },
        // { bidder: 'ix', params: {siteId: 752400, size: [320,100]} },
        // { bidder: 'ix', params: {siteId: 752400, size: [300,100]} },
        // { bidder: 'ix', params: {siteId: 752400, size: [360,100]} },
        // { bidder: 'justpremium', params: {zone: 133775, allow: ["ms"]} },
        // { bidder: 'onemobile',params: {dcn: '8a969958017c7c9d2d8a9e6a6fca0050',pos: '8a9690e9017c7ca4073fa7a20153006f',ext: {}} },
        // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        // { bidder: 'openx', params: { unit: '556399825', delDomain: 'lqr-d.openx.net' } },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_StickyBannerMobile' } },
        // { bidder: 'sovrn', params:{ tagid: 972319 } },
        // { bidder: 'sovrn', params:{ tagid: 972320 } },
        // { bidder: 'sovrn', params:{ tagid: 972321 } },
        // //{ bidder: 'teads', params: {pageId: '139117', placementId: '138867'} },
        // { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_StickyBanner_Prebid'} },
        // { bidder: 'yieldone', params: {placementId: '133372'} },
        // { bidder: 'sharethrough', params: {pkey: 'aDPNiyux9jmD6xACulGf0LeH'} },
        // { bidder: 'sharethrough', params: {pkey: 'tEfylm8ldlwFt9rq5hIWT0pV'} }
      ]
    },
    
    {
      "condition": window.screen.width < 812,
      "id": "MPUCenterRight",
      "name": "MPUCenterRight",
      "path": "/76730613/MPUCenterRight",
      "sizes": [[300, 250], [320, 100], [336,280]],
      "mobile_bidders": [
        // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'MPUCenterRight', environment:'mobile', placement:'MPUCenterRight' } },
        // { bidder: 'adform', params: { mid: 1182027 } },
        // { bidder: 'appnexus', params: { placementId: '23075454' } },
        // { bidder: 'criteo', params: { networkId: 8147 } },
        // { bidder: 'ix',  params: { siteId: 752391, size: [300, 250] } },
        // { bidder: 'ix',  params: { siteId: 752391, size: [336, 280] } },
        // { bidder: 'ix',  params: { siteId: 752391, size: [320, 100] } },
        // //{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
        // { bidder: 'onemobile' ,params: { dcn:'8a969958017c7c9d2d8a9e6a6fca0050', pos:'8a9695b0017c7ca40d44a7a203430062',ext: {}} },
        // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        // { bidder: 'openx', params: { unit: '556399823', delDomain: 'lqr-d.openx.net' } },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_MPUCenterRight' } },
        // { bidder: 'sovrn', params:{ tagid: 972328 } },
        // { bidder: 'sovrn', params:{ tagid: 972329 } },
        // { bidder: 'sovrn', params:{ tagid: 972330 } },
        // { bidder: 'teads', params: {pageId: '139117', placementId: '152660'} },
        // { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_MPUCenterRight_Prebid'} },
        // { bidder: 'yieldone', params: {placementId: '133375'} },
        // { bidder: 'sharethrough', params: {pkey: 'cZ6IMdi4DKDEM0Vz2uLhKcBe'} },
        // { bidder: 'sharethrough', params: {pkey: 'QmdyTtUCVgCvheg3ktIxLjEc'} },
        // { bidder: 'sharethrough', params: {pkey: 'SkxbnVHNaxlbS2eGy9LwkZKM'} }
      ]
    },
    {
      "condition": window.screen.width > 812,
      "id": "LeaderDesktopInfinite",
      "name": "LeaderDesktopInfinite",
      "path": "/76730613/LeaderDesktopInfinite",
      "sizes": [[728,90]],
      "bidders": [
        // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'LeaderDesktopInfinite', environment:'desktop', placement:'LeaderDesktopInfinite' } },
        // { bidder: 'adform', params: { mid: 1182040 } },
        // { bidder: 'appnexus', params: {placementId: '23075357'} },
        // { bidder: 'criteo', params: { networkId: 8147 } },
        // { bidder: 'ix', params: {siteId: 752379, size: [728, 90]} },
        // //{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
        // { bidder: 'onemobile',params: {dcn: '8a969449017c7c9d384d9e6b99160043',pos: '8a969887017c7ca40217a7a1f9ee0074',ext: {}} },
        // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        // { bidder: 'openx', params: {unit: '556399842', delDomain: 'lqr-d.openx.net'} },
        // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        // { bidder: 'sovrn', params:{ tagid: 972297 } },
        // //{ bidder: 'teads', params: {pageId: '139117', placementId: '138867'} },
        // { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_desktop_LeaderDesktopInfinite_Prebid'} },
        // { bidder: 'yieldone', params: {placementId: '133360'} },
        // { bidder: 'sharethrough', params: {pkey: 'hZx3rvafOiknh2Go45RhQM1b'} }
      ],
    },
      {
        "condition": false,
        "id": "MPU_Parallax",
        "name": "MPU_Parallax",
        "path": "/76730613/MPU_Parallax",
        "sizes": [[336, 280], [320, 480], [300, 250], [300,600], [160,600]],
        "bidders": [
          // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'MPU_Parallax', environment:'mobile', placement:'MPU_Parallax' } },
          // { bidder: 'adform', params: { mid: 1182053 } },
          // { bidder: 'appnexus', params: { placementId: '23075453' } },
          // { bidder: 'criteo', params: { networkId: 8147 } },
          // { bidder: 'openx', params: { unit: '556399856', delDomain: 'lqr-d.openx.net' } },
          // { bidder: 'teads', params: {pageId: '139117', placementId: '152661'} },
          // { bidder: 'ix', params: {siteId: 752392, size: [300,250]} },
          // { bidder: 'ix', params: {siteId: 752392, size: [320,480]} },
          // { bidder: 'ix', params: {siteId: 752392, size: [336,280]} },
          // { bidder: 'ix', params: {siteId: 752392, size: [300,600]} },
          // { bidder: 'ix', params: {siteId: 752392, size: [160,600]} },
          // //{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
          // { bidder: 'onemobile',params: {dcn: '8a969958017c7c9d2d8a9e6a6fca0050',pos: '8a969887017c7ca40217a7a207f90076',ext: {}}},
          // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          // { bidder: 'sovrn', params:{ tagid: 972314 }},
          // { bidder: 'sovrn', params:{ tagid: 972315 }},
          // { bidder: 'sovrn', params:{ tagid: 972316 }},
          // { bidder: 'sovrn', params:{ tagid: 972317 }},
          // { bidder: 'sovrn', params:{ tagid: 972318 }},
          // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_MPUInfiniteMobile' } },
          // { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_MPUParallax_Prebid'} },
          // { bidder: 'yieldone', params: {placementId: '133371'} },
          // { bidder: 'sharethrough', params: {pkey: 'Fd9eQBbaV3H3couu93xb70ws'} },
          // { bidder: 'sharethrough', params: {pkey: '74DH129Tl1oxGxirTFYYBE8q'} },
          // { bidder: 'sharethrough', params: {pkey: 'SK1ojNpZASCzN5GrSKLZuwN0'} },
          // { bidder: 'sharethrough', params: {pkey: 'wxSrdApmTNRGIPzrfnD8Yd6H'} },
          // { bidder: 'sharethrough', params: {pkey: 'CZHiF547XhDb5r1Vg35UKfdU'} },
          // { bidder: 'sharethrough', params: {pkey: 'uzvY4bn2BQ9jjorZFUPajYKA'} }
        ],
      }
  ];


  if (window.screen.width > 1440) {
    let rcMissingIX = adUnits.find(ad => ad.id === 'RightColumn');
    let lcMissingIX = adUnits.find(ad => ad.id === 'LeftColumn');
    rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 752388, size:[300, 600]} });
    lcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 752381, size:[300, 600]} });
  }
  return adUnits;
}