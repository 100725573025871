import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  var onemobileDCN = window.screen.width > 420 ? '8a96945901757509a7551d43776b0342' : '8a96945901757509a7551d6362c80344';

  return [
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "LeaderBelowTitle",
      "name": "NEXT_Leader_BellowTitle",
      "path": "/76730613/NEXT_Leader_BellowTitle",
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
      "bidders": [
      //   {
      //     bidder: 'adagio',
      //     params: {
      //         organizationId: '1067',
      //         site: 'trendscatchers-com',
      //         adUnitElementId: 'LeaderBelowTitle',
      //         environment: 'desktop',
      //         placement: 'NEXT_Leader_BellowTitle'
      //     }
      // },
      // {bidder: 'adform', params: {mid: 980028}},
      // // {bidder: 'appnexus', params: {placementId: '20942283'}},
      // {bidder: 'criteo', params: {networkId: 8147}},
      // {bidder: 'ix', params: {siteId: 623506, size: [728, 90]}},
      // {bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
      // //{ bidder: 'districtm',  params: {  placementId: 16005275 } },
      // //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
      // {bidder: 'openx', params: {unit: '542520983', delDomain: 'lqr-d.openx.net'}},
      // {
      //     bidder: 'onemobile',
      //     params: {
      //         dcn: '8a96945901757509a7551d43776b0342',
      //         pos: '8a96910d0177778c7fdb8ce898f100b8',
      //         ext: {}
      //     }
      // },
      // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
      // {
      //     // bidder: 'pubmatic',
      //     params: {publisherId: '159940', adSlot: 'com_desktop_next_Leader_bellowTitle'}
      // },
      // //{ bidder: 'richaudience', params: { pid: 'QReieyrtGC', supplyType: 'site' }},
      // {bidder: 'sovrn', params: {tagid: 861241}},
      // //{ bidder: 'teads', params: { pageId: '113724', placementId: '135706'} },
      // {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}}
      ],
      "mobile_bidders": [
      //   {
      //     bidder: 'adagio',
      //     params: {
      //         organizationId: '1067',
      //         site: 'trendscatchers-com',
      //         adUnitElementId: 'LeaderBelowTitle',
      //         environment: 'mobile',
      //         placement: 'NEXT_Leader_BellowTitle'
      //     }
      // },
      // {bidder: 'adform', params: {mid: 980034}},
      // // {bidder: 'appnexus', params: {placementId: '20942283'}},
      // {bidder: 'criteo', params: {networkId: 8147}},
      // //{ bidder: 'districtm',  params: { placementId: 16005275 } },
      // //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
      // {bidder: 'ix', params: {siteId: 623528, size: [300, 250]}},
      // {bidder: 'ix', params: {siteId: 623528, size: [336, 280]}},
      // {bidder: 'ix', params: {siteId: 623528, size: [320, 100]}},
      // {bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
      // {
      //     bidder: 'onemobile',
      //     params: {
      //         dcn: '8a96945901757509a7551d6362c80344',
      //         pos: '8a969d8e0177778c80348ce8ab5b00c1',
      //         ext: {}
      //     }
      // },
      // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
      // {bidder: 'openx', params: {unit: '543888597', delDomain: 'lqr-d.openx.net'}},
      // // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_mobile_next_Leader_bellowTitle'}},
      // //{ bidder: 'richaudience', params: { pid: '6yQQzMZSfj', supplyType: 'site' }},
      // {bidder: 'sovrn', params: {tagid: 861247}},
      // //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
      // {bidder: 'teads', params: {pageId: '121182', placementId: '135710'}},
      // {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}}
      ],
    },
    {
      "condition": window.screen.width < 812,
      "id": "MPUTopRight",
      "name": "NEXT_MPUTopRight",
      "path": "/76730613/NEXT_MPUTopRight",
      "sizes": [[300, 250],[336,280],[320,100]],
      "mobile_bidders": [
      //   {
      //     bidder: 'adagio',
      //     params: {
      //         organizationId: '1067',
      //         site: 'trendscatchers-com',
      //         adUnitElementId: 'MPUTopRight',
      //         environment: 'mobile',
      //         placement: 'NEXT_MPUTopRight'
      //     }
      // },
      // {bidder: 'adform', params: {mid: 951115}},
      // // {bidder: 'appnexus', params: {placementId: '20942284'}},
      // {bidder: 'criteo', params: {networkId: 8147}},
      // //{ bidder: 'districtm', params: { placementId: 16005279 } },
      // //{ bidder: 'districtmDMX', params: { dmxid: 367605, memberid : 101868 } },
      // {bidder: 'ix', params: {siteId: 623512, size: [300, 250]}},
      // {bidder: 'ix', params: {siteId: 623512, size: [336, 280]}},
      // {bidder: 'ix', params: {siteId: 623512, size: [320, 100]}},
      // {bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
      // {
      //     bidder: 'onemobile',
      //     params: {
      //         dcn: '8a96945901757509a7551d6362c80344',
      //         pos: '8a969d8e0177778c80348ce8b18a00c2',
      //         ext: {}
      //     }
      // },
      // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
      // {bidder: 'openx', params: {unit: '543888602', delDomain: 'lqr-d.openx.net'}},
      // // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_mobile_next_MPUTopRight'}},
      // //{ bidder: 'richaudience', params: { pid: 'hoS2bUVNvb', supplyType: 'site' }},
      // {bidder: 'sovrn', params: {tagid: 861249}},
      // //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
      // {bidder: 'teads', params: {pageId: '121182', placementId: '135711'}},
      // {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_MPUTopRight_Prebid"}}
      ]
    },
    {
      "condition": window.screen.width > 812,
      "id": "RightColumn",
      "name": "NEXT_RightColumnHalfPage1",
      "path": "/76730613/NEXT_RightColumnHalfPage1",
      "sizes": window.screen.width > 1440 ? [[300, 600],[160, 600]] : [[160, 600]],
      "bidders": [
      //   {
      //     bidder: 'adagio',
      //     params: {
      //         organizationId: '1067',
      //         site: 'trendscatchers-com',
      //         adUnitElementId: 'RightColumn',
      //         environment: 'desktop',
      //         placement: 'NEXT_RightColumnHalfPage1'
      //     }
      // },
      // {bidder: 'adform', params: {mid: 997239}},
      // {bidder: 'sovrn', params: {tagid: 866901}},
      // {bidder: 'sovrn', params: {tagid: 934828}},
      // // {bidder: 'appnexus', params: {placementId: '20942290'}},
      // {bidder: 'criteo', params: {networkId: 8147}},
      // //{ bidder: 'districtm', params: { placementId: 16005296 } },
      // //{ bidder: 'districtmDMX', params: { dmxid: 367608, memberid : 101868 } },
      // {bidder: 'ix', params: {siteId: 623502, size: [160, 600]}},
      // {bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
      // {
      //     bidder: 'onemobile',
      //     params: {
      //         dcn: onemobileDCN,
      //         pos: '8a9694a00178782103332106bdfe0005',
      //         ext: {}
      //     }
      // },
      // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
      // {bidder: 'openx', params: {unit: '543888594', delDomain: 'lqr-d.openx.net'}},
      // // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_desktop_next_MPUTopRight1'}},
      // //{ bidder: 'richaudience', params: { pid: 'HOpAKTXHmc', supplyType: 'site' }},
      // {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
      // //{ bidder: 'triplelift', params: { inventoryCode: "TrendscatchersCOM_NEXT_RightColumnHalfPage_Prebid"} }
      ]
    },
    {
      "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "LeftColumn",
      "name": "NEXT_LeftColumnHalfPage",
      "path": "/76730613/NEXT_LeftColumnHalfPage",
      "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600]] : [[160, 600]],
      "bidders": [
      //   {
      //     bidder: 'adagio',
      //     params: {
      //         organizationId: '1067',
      //         site: 'trendscatchers-com',
      //         adUnitElementId: 'LeftColumn',
      //         environment: 'desktop',
      //         placement: 'NEXT_LeftColumnHalfPage'
      //     }
      // },
      // {bidder: 'adform', params: {mid: 1088951}},
      // {bidder: 'ix', params: {siteId: 697578, size: [160, 600]}},
      // {bidder: 'sovrn', params: {tagid: 861245}},
      // {bidder: 'sovrn', params: {tagid: 861246}},
      // // {bidder: 'appnexus', params: {placementId: '22129020'}},
      // {bidder: 'openx', params: {unit: '543986374', delDomain: 'lqr-d.openx.net'}},
      // // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'trendscatchers.com_desktop_NEXT_LeftColumnHalfPage'}},
      // { bidder: 'triplelift', params: { inventoryCode: "TrendscatchersCOM_desktop_NEXT_LeftColumnHalfPage_Prebid"} },
      // {
      //     bidder: 'onemobile',
      //     params: {
      //         dcn: onemobileDCN,
      //         pos: '8a969d33017a7af1bd7df774192b01b6',
      //         ext: {}
      //     }
      // },
      // {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
      ]
    }
  ];

}
